<template>
  <template v-for="(item, idx) in media" :key="item">
    <nuxt-img
      v-if="item?.type === 'image'"
      :src="item?.path"
      format="webp"
      height="60"
      width="60"
      fit="contain"
      loading="lazy"
      class="cursor-pointer w-[60px] h-[60px] object-cover rounded-md max-w-[60px] max-h-[80px]"
      @click="openModal(idx)"
    />
    <div
      v-else
      class="relative flex items-center justify-center w-[60px] h-[60px] rounded-md bg-[#FFF]"
      :style="{
        background: `url('${youTubeVideoBackground}') center center no-repeat`
      }"
      @click.prevent.stop="openModal(idx)"
    >
      <LazyIconsSocialsYoutubeGray
        color="#FF0000"
        triangle-color="#FFF"
        class="w-[25px] h-[25px]"
      />
    </div>
  </template>
  <ProductSectionsDetailsReviewsImageModal
    :id="review?.id"
    v-model="showModal"
    :initial-slide="initialSlide"
    :media="media"
  />
</template>

<script setup>
import ProductFeedbackEntity from '~/entities/ProductFeedback.entity'

const props = defineProps({
  slide: {
    type: Object,
    default: () => {}
  }
})

const review = computed(() => {
  return new ProductFeedbackEntity(props.slide)
})

const media = computed(() => {
  if (props.slide?.youtube) {
    return [
      ...review.value?.getMedia(),
      {
        type: 'video',
        code: 'video',
        value: review.value?.youtube
      }
    ]
  }
  return review.value?.getMedia()
})

const youTubeVideoBackground = computed(() => {
  const regex =
    /(?:youtu\.be\/|youtube\.com\/(?:.*[?&]v=|embed\/|v\/|shorts\/))([\w-]{11})/
  const match = review.value?.youtube?.match(regex)
  return match ? `https://img.youtube.com/vi/${match[1]}/hqdefault.jpg` : null
})

const initialSlide = ref(0)
const showModal = ref(false)

const openModal = index => {
  showModal.value = true
  initialSlide.value = index
}
</script>
