<template>
  <div class="relative width-full max-w-full">
    <!-- Prev button -->
    <button
      ref="prevButton"
      type="button"
      class="z-10 absolute left-0 text-green-ibis disabled:text-gray-mud"
      :class="{
        'top-[200px]': props.size === 'sm',
        'top-[50%]': props.size === 'lg'
      }"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8359 1.375L5.50098 11L14.8359 20.625"
          stroke="currentColor"
          stroke-linecap="round"
        />
      </svg>
    </button>

    <!-- Next Button -->
    <button
      ref="nextButton"
      type="button"
      class="z-10 absolute right-0 text-green-ibis disabled:text-gray-mud"
      :class="{
        'top-[200px]': props.size === 'sm',
        'top-[50%]': props.size === 'lg'
      }"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.16406 1.375L16.499 11L7.16406 20.625"
          stroke="currentColor"
          stroke-linecap="round"
        />
      </svg>
    </button>

    <!-- Preview Slider -->
    <swiper
      :space-between="10"
      :navigation="{
        nextEl: nextButton,
        prevEl: prevButton
      }"
      :thumbs="{ swiper: thumbsSwiper }"
      :modules="modules"
      :initial-slide="props.initialSlide"
      class="preview-swiper w-full"
      :zoom="props.allowZoom"
      :class="{
        'h-[300px] lg:h-[400px]': props.size === 'sm',
        'h-auto max-h-[80%]': props.size === 'lg'
      }"
      @swiper="setPreviewSwiper"
      @slide-change="slideChange"
      @active-index-change="stopVideos"
    >
      <swiper-slide
        v-if="!props.images?.length"
        class="!flex self-center px-10 h-full items-center justify-center"
      >
        <nuxt-img
          class="m-auto object-contain h-full max-w-full"
          :src="NO_IMAGE_PATH"
          fit="contain"
          format="webp"
          :width="props.size === 'sm' ? '460' : '1256'"
          :loading="index ? 'lazy' : 'eager'"
        />
      </swiper-slide>
      <swiper-slide
        v-for="(image, index) in props.images"
        :key="'media-' + index"
        class="!flex self-center px-10 h-full items-center justify-center"
      >
        <div
          v-if="
            image && image?.code !== 'video' && image?.code !== 'video-shorts'
          "
          class="swiper-zoom-container"
        >
          <nuxt-img
            class="m-auto object-contain max-w-full py-10"
            :src="
              useAssetUrl(props.originalPath ? image.pathOriginal : image.path)
            "
            :class="[
              props.isModalSlider ? 'h-[80vh]' : 'h-full cursor-zoom-in'
            ]"
            format="webp"
            fit="contain"
            :width="props.size === 'sm' ? '600' : '1256'"
            :loading="index ? 'lazy' : 'eager'"
            @click="zoomIn"
          />
        </div>
        <!--        <nuxt-img-->
        <!--          class="m-auto object-contain max-w-full py-5"-->
        <!--          :src="-->
        <!--            useAssetUrl(props.originalPath ? image.pathOriginal : image.path)-->
        <!--          "-->
        <!--          :class="[props.isModalSlider ? 'h-[80vh]' : 'h-full cursor-zoom-in']"-->
        <!--          format="webp"-->
        <!--          fit="contain"-->
        <!--          :width="props.size === 'sm' ? '600' : '1256'"-->
        <!--          :loading="index ? 'lazy' : 'eager'"-->
        <!--          @click="zoomIn"-->
        <!--        />-->
        <div
          v-else
          class="rounded-[5px]"
          :class="[
            props.size === 'sm' ? 'w-[600px]' : 'w-[1256px]',
            props.isModalSlider ? 'h-[60vh]' : 'aspect-w-16 aspect-h-9'
          ]"
        >
          <VueYtframe
            ref="ytVideo"
            class="rounded-[5px]"
            :video-id="getYouTubeVideoID(image?.value)"
          />
        </div>
      </swiper-slide>
    </swiper>
    <div
      v-if="!props.isModalSlider"
      class="flex flex-wrap space-x-3 block md:hidden mx-auto justify-center w-full"
    >
      <div
        v-for="index in props.images?.length"
        :key="index"
        class="indicator h-[3px] w-4 rounded bg-green-ibis mb-2"
        :class="[currentIndex === index - 1 ? 'opacity-100' : 'opacity-30']"
      ></div>
    </div>

    <div v-if="props.showLargerButton" class="flex justify-center mt-5">
      <a
        href="#"
        class="flex items-center gap-1 text-gray-mud"
        @click.prevent="zoomIn"
      >
        <IconsProductSearch class="h-4 w-4" />
        <span class="text-[12px]"> {{ t('Натисніть щоб збільшити') }} </span>
      </a>
    </div>

    <!-- Thumbnails slider -->
    <div
      v-if="props.showThumbs && props.imageThumbs?.length"
      class="relative width-full hidden md:block max-w-full px-6"
    >
      <!-- Prev button -->
      <button
        ref="thumbPrevButton"
        type="button"
        class="z-10 absolute left-0 text-green-ibis disabled:text-gray-mud bottom-1/2 translate-y-[11px]"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8359 1.375L5.50098 11L14.8359 20.625"
            stroke="currentColor"
            stroke-linecap="round"
          />
        </svg>
      </button>

      <!-- Next Button -->
      <button
        ref="thumbNextButton"
        type="button"
        class="z-10 absolute right-0 text-green-ibis disabled:text-gray-mud bottom-1/2 translate-y-[11px]"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.16406 1.375L16.499 11L7.16406 20.625"
            stroke="currentColor"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <swiper
        :navigation="{
          nextEl: thumbNextButton,
          prevEl: thumbPrevButton
        }"
        :space-between="9"
        :slides-per-view="7"
        :breakpoints="{
          300: {
            slidesPerView: 4
          },
          600: {
            slidesPerView: 5
          },
          750: {
            slidesPerView: 6
          },
          900: {
            slidesPerView: 7
          },
          1024: {
            slidesPerView: 4
          },
          1200: {
            slidesPerView: 5
          },
          1350: {
            slidesPerView: 6
          },
          1500: {
            slidesPerView: 7
          }
        }"
        :watch-slides-progress="true"
        :modules="[...modules, SwiperGrid]"
        class="thumb-swiper h-auto py-[10px] mt-5"
        @swiper="setThumbsSwiper"
      >
        <swiper-slide
          v-for="(image, index) in props.imageThumbs"
          :key="'image-thumbnail-' + index"
          class="!h-[60px] w-[60px] !flex items-center justify-center"
        >
          <nuxt-img
            v-if="
              image &&
                image?.code !== 'video' &&
                image?.code !== 'video-shorts' &&
                image?.code !== 'video-review'
            "
            class="flex-1 object-contain h-full w-full bg-white"
            :src="useAssetUrl(image.path)"
            fit="outside"
            height="60"
            width="60"
            format="webp"
            loading="lazy"
          />
          <div
            v-else
            class="w-full h-full flex flex-col justify-center items-center relative"
          >
            <ProductSectionsDetailsYouTubeFrame
              class="w-[60px] h-[60px]"
              :url="image.value"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/grid'
import 'swiper/css/zoom'
import last from 'lodash/last'

const { t } = useI18n()

const props = defineProps({
  images: {
    type: Array,
    default: () => []
  },
  imageThumbs: {
    type: Array,
    default: () => []
  },
  allowZoom: {
    type: Boolean,
    default: false
  },
  showLargerButton: {
    type: Boolean,
    default: false
  },
  showThumbs: {
    type: Boolean,
    default: true
  },
  initialSlide: {
    type: Number,
    default: 0
  },
  size: {
    type: String,
    default: 'sm'
  },
  originalPath: {
    type: Boolean,
    default: false
  },
  isModalSlider: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['onZoom'])

const modules = ref([SwiperFreeMode, SwiperNavigation, SwiperThumbs])
if (props.allowZoom) {
  modules.value.push(SwiperZoom)
}

const previewSwiper = ref(null)
const thumbsSwiper = ref(null)
const nextButton = ref(null)
const prevButton = ref(null)
const thumbNextButton = ref(null)
const thumbPrevButton = ref(null)
const currentIndex = ref(0)

const getYouTubeVideoID = url => {
  const regex =
    /(?:youtu\.be\/|youtube\.com\/(?:.*[?&]v=|embed\/|v\/|shorts\/))([\w-]{11})/
  const match = url.match(regex)
  return match ? match[1] : null
}

const slideChange = instance => {
  currentIndex.value = instance.activeIndex
}

const setPreviewSwiper = swiper => {
  previewSwiper.value = swiper
}
const setThumbsSwiper = swiper => {
  thumbsSwiper.value = swiper
}

const zoomIn = () => {
  if (props.isModalSlider) {
    return false
  }
  emits('onZoom', previewSwiper.value.realIndex)
}

const ytVideo = ref(null)

const stopVideos = () => {
  ytVideo.value?.forEach(video => {
    video?.stopVideo()
  })
}
// watch(() => previewSwiper.value?.activeIndex, () => {
//   console.log('previewSwiper', previewSwiper.value?.activeIndex)
// })

// watch(() => previewSwiper.value.activeIndex, () => {
//   console.log('previewSwiper', previewSwiper.value.activeIndex)
// })
</script>

<style lang="postcss" scoped>
.preview-swiper {
  @apply flex items-center justify-center;
}
.thumb-swiper .swiper-slide-thumb-active {
  border: 1px solid green;
  /* @apply border-[1px] border-green-ibis; */
}
</style>
